import { RegularButton } from "@/components/Button/RegularButton/RegularButton.component";
import { Translation } from "@/components/Translation/Translation.component";
type NewsletterSignUpFailedBoxProps = {
  readonly reset: () => void;
  readonly variant?: "accent" | "primary";
};
export function NewsletterSignUpFailedBox({
  reset,
  variant = "primary"
}: NewsletterSignUpFailedBoxProps) {
  return <div className="min-h-[300px] px-2 py-3" data-sentry-component="NewsletterSignUpFailedBox" data-sentry-source-file="NewsletterSignUpFailedBox.component.tsx">
      <p className="leading-snug">
        <Translation model={{
        da: "Det lykkedes ikke at tilmelde dig nyhedsbrevet.",
        de: "Bei der Anmeldung zu unserem Newsletter ist ein Fehler aufgetreten.",
        en: "There was an error subscribing to the newsletter.",
        no: "Noe gikk galt under påmeldingen til nyhetsbrevet.",
        sv: "Det gick inte att prenumerera på nyhetsbrevet."
      }} data-sentry-element="Translation" data-sentry-source-file="NewsletterSignUpFailedBox.component.tsx" />
      </p>
      <RegularButton className="mt-2 w-full" onClick={reset} variant={variant} data-sentry-element="RegularButton" data-sentry-source-file="NewsletterSignUpFailedBox.component.tsx">
        <Translation model={{
        da: "Prøv igen",
        de: "Versuchen Sie es bitte erneut",
        en: "Try again",
        no: "Prøv igjen",
        sv: "Försök igen"
      }} data-sentry-element="Translation" data-sentry-source-file="NewsletterSignUpFailedBox.component.tsx" />
      </RegularButton>
    </div>;
}