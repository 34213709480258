"use client";

import type { ConversionEvent } from "@/components/Tracking/Kilkaya/kilkaya.types";
import { Translation } from "@/components/Translation/Translation.component";
import { useKilkayaConversion } from "@/hooks/tracking/kilkaya/use-kilkaya-tracking.hook";
import { useTracking } from "@/hooks/tracking/use-tracking";
import { useTrackingVersion } from "@/hooks/tracking/use-tracking-version";
import type { Locale } from "@/ts/locale/locale.types";
type NewsletterSignUpSuccessBoxProps = {
  readonly conversionEvent?: ConversionEvent | null;
};
export const newsletterSuccessMessage: Record<Locale, string> = {
  da: "Du er nu tilmeldt vores nyhedsbrev",
  de: "Sie sind jetzt für unseren Newsletter angemeldet.",
  en: "You are now subscribed to our newsletter.",
  no: "Du er nå påmeldt vårt nyhetsbrev",
  sv: "Du är nu prenumerant på vårt nyhetsbrev."
};
export function NewsletterSignUpSuccessBox({
  conversionEvent = null
}: NewsletterSignUpSuccessBoxProps) {
  const {
    trackConversion
  } = useTracking();
  const trackingVersion = useTrackingVersion();
  useKilkayaConversion(trackingVersion === "v1" ? conversionEvent : null);
  trackConversion(trackingVersion === "v2" ? conversionEvent : null);
  return <div className="min-h-[300px] px-2 py-3" data-sentry-component="NewsletterSignUpSuccessBox" data-sentry-source-file="NewsletterSignUpSuccessBox.component.tsx">
      <h1 className="mb-1 text-2xl font-theme-bold tracking-tight lg:text-3xl">
        <Translation model={newsletterSuccessMessage} data-sentry-element="Translation" data-sentry-source-file="NewsletterSignUpSuccessBox.component.tsx" />
      </h1>
    </div>;
}