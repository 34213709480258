"use client";

import { useSite } from "@/contexts/site/site.context";
import { type ForwardedRef, type InputHTMLAttributes, forwardRef } from "react";
import { DefaultInput } from "../components/DefaultInput/DefaultInput.component";

/**
 * A slightly modified regular expression which makes the top-level domain mandatory (e.g. it disallows `example@localhost`).
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
 * @see https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
 * @see https://www.icann.org/en/announcements/details/new-gtld-dotless-domain-names-prohibited-30-8-2013-en
 */
// language=JSUnicodeRegexp
const rfc5322EmailRegex = "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]+@[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?)+$";
type EmailProps = Omit<InputHTMLAttributes<HTMLInputElement>, "placeholder" | "type"> & {
  readonly description?: string;
  readonly onClear?: () => void;
};
function EmailInput(defaultInputProps: EmailProps, ref: ForwardedRef<HTMLInputElement>) {
  const {
    locale
  } = useSite();
  return <DefaultInput autoComplete="email" placeholder={{
    da: "Email",
    de: "E-mail-Adresse",
    en: "E-mail address",
    no: "E-postadresse",
    sv: "E-postadress"
  }[locale]} ref={ref} {...defaultInputProps} data-testid="email" pattern={rfc5322EmailRegex} type="email" data-sentry-element="DefaultInput" data-sentry-component="EmailInput" data-sentry-source-file="Email.component.tsx" />;
}
export const Email = forwardRef(EmailInput);