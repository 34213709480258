import clsx from "clsx";
import type { ReactNode } from "react";
import { Checkbox, type CheckboxProps } from "./Checkbox.component";
type CheckboxWithLabelProps = CheckboxProps & {
  readonly children?: ReactNode;
  readonly variant?: "accent" | "primary";
};
export function CheckboxWithLabel({
  children,
  className,
  defaultChecked = false,
  id,
  isDisabled = false,
  variant = "primary",
  ...rest
}: CheckboxWithLabelProps) {
  return <span className="flex gap-x-1" data-sentry-component="CheckboxWithLabel" data-sentry-source-file="CheckboxWithLabel.component.tsx">
      <Checkbox id={id} isDisabled={isDisabled} {...rest} className="peer" defaultChecked={defaultChecked} variant={variant} data-sentry-element="Checkbox" data-sentry-source-file="CheckboxWithLabel.component.tsx" />
      <label className={clsx("transition-all motion-reduce:transition-none", "text-base peer-required:after:inline-block peer-required:after:align-text-top peer-required:after:text-sm peer-required:after:leading-none peer-required:after:text-error-400 peer-required:after:content-['*']", isDisabled ? "text-nobel" : "cursor-pointer text-inherit", className)} htmlFor={id}>
        {children}
      </label>
    </span>;
}